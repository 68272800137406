// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-ateliers-js": () => import("./../../../src/pages/ateliers.js" /* webpackChunkName: "component---src-pages-ateliers-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infos-js": () => import("./../../../src/pages/infos.js" /* webpackChunkName: "component---src-pages-infos-js" */),
  "component---src-pages-shiatsu-js": () => import("./../../../src/pages/shiatsu.js" /* webpackChunkName: "component---src-pages-shiatsu-js" */)
}

